import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { dismissTradePointsNotice, tradeBankPointsForCoupon } from "../../store/user/actions";
import Button from "../Button";
import ErrorMessage from "../ErrorMessage";
import LockOpenIcon from "../icons/LockOpen.svg";
import Modal from "../Modal";
import * as styles from "./index.module.scss";

export default ({
  appStyles,
  T,
  modalOpen,
  closeModal,
  giftToUnlock,
  onSuccessfulUnlock,
  shouldSyncGifts,
  redeemAfterTrade = false
}) => {
  const dispatch = useDispatch();
  const { user } = useSelector(({ user }) => ({ user }));

  const [hasBeenSent, setHasBeenSent] = useState(false);

  const handleUnlockReward = () => {
    dispatch(tradeBankPointsForCoupon(giftToUnlock, { shouldSyncGifts, redeemAfterTrade }));
    setHasBeenSent(true);
  };

  useEffect(() => {
    dispatch(dismissTradePointsNotice());
  }, [])

  useEffect(() => {
    if (!user.tradingPointsForCoupon && hasBeenSent) {
      onSuccessfulUnlock();
    }
  }, [user.tradingPointsForCoupon, user.loyaltyProfile]);

  return (
    <Modal
      open={modalOpen}
      onClose={closeModal}
      appStyles={appStyles}
      style={{ width: "100%" }}
    >
      <div className={styles.iconContainer}>
        <div
          style={{ color: appStyles.actionColor }}
          className={styles.SVGWrapper}
        >
          <LockOpenIcon />
        </div>
        <Typography variant="h5">{T("Unlock reward for points")}</Typography>
      </div>
      <Typography align="center" sx={{ mt: 3 }}>
        {T(
          `Are you sure you want to unlock the reward "${
            giftToUnlock.coupon.title
          }" for ${giftToUnlock.price} points?`
        )}
      </Typography>
      {user.tradePointsError && (
        <ErrorMessage appStyles={appStyles}>
          {T(
            "Something went wrong while trading a reward, please try again later"
          )}
        </ErrorMessage>
      )}
      <Button
        centered
        appStyles={appStyles}
        onClick={handleUnlockReward}
        style={{ margin: 20 }}
        loading={user.tradingPointsForCoupon}
      >
        {T("Unlock")}
      </Button>
      <Button
        linkStyle
        centered
        appStyles={appStyles}
        onClick={closeModal}
        style={{ margin: 20 }}
      >
        {T("Cancel")}
      </Button>
    </Modal>
  );
};
